<template lang="pug">
div
  .brand-wrapper
    .container-fluid.px-3
      .row.title
        .col-12
          .brand-campaigns-editing-wrapper
            editable-title(h1 v-if="campaign.name" :title="campaignName", @renameTitle="renameCampaign")
          template(v-if="!campaign.domain")
            .brand-link(@click="$modal.show('domain-management')") {{ $t('setupADomain') }}
          template(v-else)
            template(v-if="domainCount === 1")
              .campaign-domain-editing
                input(class="dynamic-input campaign-name-ellipsis inline-edit"
                      type="text"
                      v-autowidth="{minWidth: '20px', comfortZone: 20}"
                      v-model="newDomain"
                      @keydown.27="cancel"
                      @change="renameDomain"
                      @focus="$refs.dynamicDomainInput.select()"
                      ref="dynamicDomainInput")
            template(v-else-if="isDynamicContent")
              .campaign-domain-editing
                span.mr-2.py-2 {{ campaign.domain }}
            template(v-else)
              .campaign-domain-editing(@click="$modal.show('domain-management')")
                span.mr-2.py-2.inline-edit {{ campaign.domain }}
      alert.has-no-embed-poistions-alert(v-if="isEmbedded && hasNoEmbedPoisitions")
        span {{ $t('hasNoEmbedPoistionAlertChoose') }}
        span.highlight(@click="openWebSelector") {{ $t('hasNoEmbedPoistionAlertPointClick') }}
        span {{ $t('hasNoEmbedPoistionAlertOr') }}
        span.highlight(@click="$modal.show('embed-code')") {{ $t('hasNoEmbedPoistionAlertEmbedCode') }}
        span {{ $t('hasNoEmbedPoistionAlertFinish') }}
        om-modal(name="embed-code"
                width="750"
                class="brand-embedded-modal"
                color="light")
          template(slot="modal-header")
            .w-100.text-left.font-weight-bold.font-size-1--25 {{ $t('embedCode.title') }}
          template(slot="modal-body")
            .mt-1.mb-2.text-left {{ $t('embedCode.desc') }}
            highlight.embed-code-highlight(:codeStr="$t('embedCode.code', { campaign: this.$route.params.id })")
          template(slot="modal-footer")
            .d-flex.justify-content-end
              om-button(ghost @click="$modal.hide('embed-code')") {{ $t('cancel') }}
              om-button.ml-3(primary @click="confirmManuallyEmbedded") {{ $t('okItsDone') }}

      transition(name="fade")
        om-toast#alert-block.mb-5.mt-2(v-if="alerts && alerts.length > 0" :closable="false" :color="alertColor" type="side")
          .alert-block-header.mb-3 {{ $tc('campaignAlerts.header', alerts.length, { type: $tc(`campaignAlerts.${alertColor}`, alerts.length) }) }}
          .alert-block.mb-2(v-for="(alert, index) in alerts" :key="index")
            .alert-block-wrapper
              .alert-block-title.d-block {{ getAlertTitle(alert) }}
              .alert-block-description.d-inline-block(v-html="$t(`campaignAlerts.${getAlertTypeWithV3(alert.type)}.description`, descriptionOptions(alert))")
              om-link.d-inline-block.alert-block-link.ml-1(@click="$modal.show('campaign-alerts-modal', {activeSlide: index})" primary) {{ $t('campaignAlerts.viewDetails') }}
      transition(name="fade")
        om-toast.mb-5.mt-2(v-if="isDynamicContent && campaign.status === 'active' && !hasActiveChanges" :closable="false" color="warning" type="side")
          .alert-block-header.mb-3 {{ $t('campaignAlerts.NoActiveChange.title') }}
          .alert-block.mb-2
            .alert-block-wrapper
              .alert-block-title.d-block {{ $t('campaignAlerts.NoActiveChange.description') }}
      re-integration-campaign-alert(v-if="!isDynamicContent" :campaignIntegrations="campaign.integrations")

      .card
        .card-title.font-weight-normal.pb-3 {{ $t('campaignVariantsTitle') }}
        .card-body.brand-settings-summary-local.d-flex.flex-column
          .d-lg-flex.align-items-center.justify-content-between.mx-0.schedule-infos-top
            .col-12.col-lg-auto.d-flex.align-items-center.px-0
              template(v-if="campaign.status === 'archived'")
                om-chip.ml-0.mr-3(color="secondary" large)
                  span.text-secondary {{ $t('archived') }}
                  template(#icon)
                    BulkArchiveSvg(color="#8F97A4")
                om-button( secondary @click="restoreCampaigns") {{ $t('restore') }}
                  template(#icon)
                    ArrowClockwise
              popper(
                v-else-if="!scheduleStr.expired"
                trigger="hover"
                :options="{placement: 'top-start'}"
                transition="fade"
                enter-active-class="fade-enter-active"
                leave-active-class="fade-leave-active"
                )
                .popper.brand-tooltip.brand-tooltip-light.brand-tooltip-right.ml-10.w-18(
                  style="text-align: center !important; padding: 0.75rem !important"
                  ) {{$t('notAllowedToModifyToggle')}}
                div(slot='reference')
                    toggle-button(class="campaign-activate mb-0 mx-0"
                    :class="toggleButtonClasses"
                    :value="campaign.status === 'active'"
                    :labels="{checked: !scheduleStr.expired ? $t('scheduled') : $t('active'), unchecked: $t('inactive')}"
                    :style="toggleButtonStatus"
                    :sync="true"
                    :width="90"
                    :height="30"
                    :margin="2"
                    ref="statusToggle"
                    @change="toggleCampaignStatus($event.value ? 'active' : 'inactive')")
              toggle-button(v-else class="campaign-activate mb-0 mx-0 mt-0"
                :class="{inactive: campaign.status === 'inactive', 'in-experiment': campaign.currentExperimentName}"
                :value="campaign.status === 'active'"
                :labels="{checked: $t('active'), unchecked: $t('inactive')}"
                :sync="true"
                :width="90"
                :height="30"
                :margin="2"
                :style="(campaign.currentExperimentName) ? 'pointer-events:none' : '' "
                ref="statusToggle"
                @change="toggleCampaignStatus($event.value ? 'active' : 'inactive')")
              .schedule-btn.cursor-pointer.ml-4.d-flex.align-items-center.flex-grow-0.element-hover(@click="$modal.show('schedule')" v-if="scheduleStr.expired")
                .small-rectangle
                  UilCalender(slot="icon" size="1.5rem")
                span.font-weight-semibold {{ $t('schedule') }}
              .activate-campaign-wrapper
                popper(
                  v-if="activateCampaignPopoverVisible && isFirstCampaign"
                  :force-show="activateCampaignPopoverVisible && isFirstCampaign"
                  :options="{ placement: 'bottom-start', modifiers: { flip: { enabled: false } } }"
                  trigger="click"
                )
                  .popper.activate-campaign-popper.brand-tooltip.brand-tooltip-light.ml-2(                )
                    component(:is="'activate-campaign-tooltip'" @close="closeActivateCampaignPopover")
                  div(slot="reference")
            .col.d-md-flex.justify-content-lg-end.align-items-center.priority-buttons.px-4(v-if="!isEmbedded && !isDynamicContent" )
              div(:style="{fontSize: '12px', marginRight: '12px'}") {{ $t('campaignPrioritySettings.priority') }}:
              .btn-group
                button.btn.btn-default(@click="changeCampaignSetting('priority', 'HIGH', campaignId, fetchCampaign.bind(this, false))"
                :class="[{'selected-priority': campaign.priority === 'HIGH', border: campaign.priority !== 'HIGH' }, priorityButtonClass]"
                )
                  chevron-double-up(size="12" :style="{marginBottom: '1px'}")
                  span.ml-2.pr-1 {{ $t('campaignPrioritySettings.high') }}
                button.btn.btn-default(@click="changeCampaignSetting('priority', 'NORMAL', campaignId, fetchCampaign.bind(this, false))"
                :class="[{'selected-priority': campaign.priority === 'NORMAL' || !campaign.priority, border: campaign.priority && campaign.priority !== 'NORMAL'}, priorityButtonClass] ")
                  span {{ $t('campaignPrioritySettings.normal') }}
                button.btn.btn-default(@click="changeCampaignSetting('priority', 'LOW', campaignId, fetchCampaign.bind(this, false))"
                :class="[{'selected-priority': campaign.priority === 'LOW', border: campaign.priority !== 'LOW' }, priorityButtonClass]")
                  chevron-double-down(size="12")
                  span.ml-2.pr-1 {{ $t('campaignPrioritySettings.low') }}
              om-tooltip.ml-2(placement="top-end")
                span {{ $t('campaignPrioritySettings.campaignPrioritySettingOverlap') }}
          .d-flex.schedule-infos.mt-2(v-if="campaign.status !== 'archived'")
            .col.d-flex.align-items-center(:class="{'rectangle-custom-class': scheduleStr.dateTime.from }")
              .cursor-pointer.d-flex.align-items-center.flex-grow-0.element-hover(@click="$modal.show('schedule')" v-if="!scheduleStr.expired")
                .small-rectangle(:class="{'has-schedule': !scheduleStr.expired }")
                  UilCalender(slot="icon" size="1.5rem" :color="`#ED5A29`")
              .col.d-flex.align-items-center.rectangle-custom-class.rectangle-custom-class-small(v-if="scheduleStr.expired && scheduleStr.dateTime.to")
                .d-flex.align-items-center
                  .small-rectangle
                    fa-icon(variant="fa-exclamation-circle" size="1.375" display="flex" :style="scheduleStr.expired ? 'color: #ED5A29' : ''")
                  .d-flex.align-items-center
                    span(style="color:#ED5A29") {{ $t('campaignStopped') }} {{ scheduleStr.dateTime.to }}
              .schedule-active(v-if="!scheduleStr.expired && scheduleStr.dateTime" style="flex: 1 1 100%")
                .schedule-active-inner(style="flex: 1 1 100%")
                  .schedule-info-items
                    .schedule-info-item.py-1
                      span.white-space-nowrap(style="color: #8B5043") {{ $t('scheduleModal.startDate') }}:
                      span.ml-2.font-weight-bold(style="color:#ED5A29") {{ scheduleStr.dateTime.from }}
                    .schedule-info-item.py-1
                      span.white-space-nowrap(style="color: #8B5043" v-if="scheduleStr.dateTime.to") - {{ $t('endDate') }}:
                      span.ml-2.font-weight-bold(style="color:#ED5A29") {{ scheduleStr.dateTime.to }}
                      span.mr-2.font-weight-bold.white-space-nowrap(style="color: #ED5A29" v-if="isScheduleCurrentlyActive") - {{ $t('currentlyActive') }}
                  .schedule-info-item.schedule-info-daily.py-1
                    span.white-space-nowrap(style="color: #8B5043" v-if="getRepeatsOnDays") {{ $t('appearOn') }}
                    span.ml-2.font-weight-bold(style="color:#ED5A29") {{ getRepeatsOnDays }}
                popper.schedule-more-btn.pl-2(trigger="click" :options="{placement: 'bottom'}")
                  .popper.brand-tooltip.brand-tooltip-light.d-flex.bg-white(style="border: 1px solid #E3E5E8 !important" :class="$i18n.locale == 'en' ? 'w-10' : 'w-12'")
                    .px-1.pt-2.pb-1.w-100.d-flex.flex-wrap
                      .d-flex.mb-4.cursor-pointer.setting-link(@click="deleteScheduleFromVariants")
                        fa-icon(variant="fa-trash" size="1.25" display="inline" style="min-width: 1rem")
                        .ml-2.d-flex.align-self-end.text-dark.font-weight-bold {{ $t('scheduleModal.deleteSchedule') }}
                      .d-flex.cursor-pointer.setting-link(@click="$modal.show('schedule')")
                        fa-icon(variant="fa-calendar-o" size="1.25" display="inline" style="min-width: 1rem")
                        .ml-2.d-flex.align-self-end.text-dark.font-weight-bold {{ $t('editSchedule') }}
                  span.d-flex.m-auto(slot="reference")
                    om-button(primary ghost iconOnly icon="ellipsis-v")
          .experiment-info.mt-3(v-if="campaign.currentExperimentName" v-html="experimentInfo")
      template(v-if="campaignHasSmartABTest")
        SmartABTestReporting(
          :loadingSABTestReport="loadingSmartABTestReport"
          :smartABTest="smartABTestReport"
          :goals="goals"
          :variants="variants"
          :domain="campaign.domain"
          :campaign="campaign"
        )
      .card.a-b-test(v-if="!isUsingExperiences && !campaignHasSmartABTest")
        .d-flex.flex-grow-1.justify-content-sm-between.card-title
          .d-flex.align-items-center(style="gap:.5rem")
            div {{ $t('abTestCenter')}}
            om-button(
              v-if="hasCampaignShareFeature && !isDynamicContent && !campaign.currentExperimentName"
              secondary
              small
              @click="shareCampaign"
            ) {{ $t('campaignShare.button.shareCampaign') }}
          .d-flex.a-b-test-actions.position-relative
            om-select#goal-select.mr-2.goal-select(
              v-if="!shouldShowDefineGoalButton"
              v-model="activeGoal"
              :options="allGoals"
              optionKey="_id"
              optionText="name"
              :label="$t('conversionGoals.selectLabel')"
              labelPosition="fill"
              extendable
              :addNewText="$t('conversionGoals.addNewText')"
              @addNew="openCreateGoalModal(campaign.domainId)")
            om-button.mr-2(secondary small v-if="shouldShowDefineGoalButton" @click="openCreateGoalModal(campaign.domainId)") {{ $t('conversionGoals.defineConversionGoal') }}
            om-tooltip.d-flex.align-items-center(placement="top-end")
              span {{ $t('conversionGoals.tooltip') }}
            om-period-select#timePeriods.variant-table-period-select-box.ml-4(
              :options="dateRangeOptions"
              :value="selectedInterval"
              :locale="$i18n.locale"
              @input="setInterval"
              maxDate="today"
            )
        variant-table(ref="variantTable"
                      :domain="campaign.domain"
                      :isNew="isNew"
                      :templateName="campaign.templateName"
                      :templateType="campaign.templateType"
                      :variants="variants"
                      :updatedAt="campaign.updatedAt"
                      :variantCount="variantCount"
                      :loadingVariantsData="loadingVariantsData"
                      :isDefaultGoal="isDefaultGoal"
                      :pagination="pagination"
                      :loadingGoals="loadingGoals"
                      :loadingChanceToWin="loadingChanceToWin"
                      :isEmbedded="isEmbedded"
                      :showDeleteVariantMenu="showDeleteVariantMenu"
                      @changeVariantName="changeVariantName"
                      @refetchVariants="refetchVariants($event)"
                      @sortingChange="sorting = $event"
                      @paginationChange="pagination = $event"
                      )
          template(slot="addVariant")
            .d-flex
              om-link.add-new-variant.my-3(primary small @click="handleAddNewVariant" withIconLeft :disabled="loadingVariantsData") {{ $t('newVariant') }}
                template(slot="left-icon")
                  UilPlusCircle.mr-2(size="1.5rem")
      Experiences(
        v-if="isUsingExperiences"
        ref="experiences"
        :campaign="campaign"
        :goals="goals"
        :goal="activeGoal"
        @updateExperiencesOrder="updateExperiencesOrder"
        :allVariants="allVariants"
        :loadingGoals="loadingGoals"
        :isDefaultGoal="isDefaultGoal"
        :dateRangeOptions="dateRangeOptions"
        :selectedInterval="selectedInterval"
        :dateKey="dateKey"
        @changeExperienceName="changeExperienceName"
        @changeVariantName="changeVariantName"
        @refetchExperiences="refetchExperiences"
        @addNewExperience="refetchExperiences"
        @variantStatusUpdate="updateVariantStatus"
        @addDynamicContentToExperience="addDynamicContentToExperience"
        @changeSelectedGoal="setSelectedGoal"
        @setInterval="setInterval"
      )
      template(v-if="smartPopupPromoVisible")
        campaign-promotions(
          :allVariants="allVariants"
          :smartPopupPromoVisible="smartPopupPromoVisible"
          :experiencesPromoVisible="experiencesPromoVisible"
          :followUpPromoVisible="followUpPromoVisible"
          @startPersonalizedExperiences="onStartPersonalizedExperienceEvent"
          @followUpPromoClick="followUpPromoClick"
        )
      template(v-else)
        transition(name="fade" v-if="experiencesPromoVisible")
          StartPersonalizedExperiences( :campaign="campaign" @startPersonalizedExperiences="onStartPersonalizedExperienceEvent")
        .card.coupon-followup-promo.flex-row.align-items-center(v-if="followUpPromoVisible")
          .card-title {{ $t('followupCampaign.promoTitle') }}
            om-tooltip.m-auto(transition="fade")
              span(v-html="$t('followupCampaign.tooltip')")
              template(slot="trigger")
                UilInfoCircle
          .followup-buttons.d-flex.justify-content-end
            om-button#open-followup-modal.learn-more(secondary small @click="openFollowupExplanation")
              span {{ $t('followupCampaign.learnMore') }}
            om-button#navigate-to-followup-templates(
              primary small
              :to="{name: 'use_case_templates', params: { id: followUpUseCaseId }}")
              span {{ $t('followupCampaign.addFollowup') }}

      add-follow-up

      settings-summary(:campaign="campaign")

      variant-stat-chart(:campaign="campaign" :variants="variants")

      feedback-statistics(v-if="Object.keys(feedbackStatistics).length" :statistics="feedbackStatistics")
      .row.align-items-center.mt-6(v-if="subscribers && subscribers.length")
        .col-6
          h3 {{$t('lastLeads')}}
        .col-6.d-flex.justify-content-end
          div.mr-4(v-if="subscribers.length !== 0")
            om-button(secondary :to="{name: 'subscribers', params: {campaignId: campaignId}}") {{$t('seeAllLeads')}}
          popper(trigger="click" :options="{placement: 'left'}" ref="exportPopper")
            .popper.brand-popover.d-flex
              om-button(class="mb-0 mr-2" primary  @click="exportLeads('xlsx')") XLSX
              om-button(class="mb-0" primary @click="exportLeads('csv')") CSV
            div.popper-content(slot="reference")
              om-button(primary v-if="subscribers.length !== 0") {{$t('export')}}

      .mt-4.brand-campaigns-statistics-table.brand-campaigns-statistics-table-top-10
        template(v-if="campaign.noInputField || preventSubscriberDataStorage")
          .brand-box.brand-box-gray.text-center.pt-5.pb-5.flex-wrap.justify-content-center
            .pt-3
              img(src="@/assets/admin/img/monk-wave.png" style="width: 13.25rem; height: 11.875rem")
            .w-100.pt-4.font-size-1--5.font-weight-bold
              span {{ $t('variantPage.campaignDoesNotCollectLeads') }}
        template(v-else)
          .subscriber-container(v-if="subscribers && subscribers.length")
            .campaign-table.d-flex.flex-wrap
              .brand-campaigns-statistics-table.w-100
                subscriber-table(
                  :fields="fields"
                  :subscribers="subscribers"
                  :availableColumns="['contact', 'domain', 'date', 'customFields']"
                  :subscriberCount="10"
                  :paginate="false"
                  :columnClasses="columnClasses")
          .brand-box.brand-box-gray.text-center.pt-5.pb-5.flex-wrap.justify-content-center(v-else)
            .pt-3(v-if="!isSubUser")
              img(src="@/assets/admin/img/monk-wave.png" style="width: 13.25rem; height: 11.875rem")
            .w-100.pt-4.font-size-1--5.font-weight-bold
              span {{ $t('variantPage.noStat') }}

    domain-management(:domain.sync="campaign.domain"
                      :domainId.sync="campaign.domainId"
                      @refetch="onDomainRefetch")
    schedule(:schedule="campaign.schedule" @changeSchedule="fetchCampaign()" @deleteSchedule="deleteScheduleFromVariants()")
    old-editor-termination
    old-campaign-preview
    alert-modal(v-if="alerts"  :alerts="alerts" :campaign="campaign" :variants="variantList[dateKey]" @dismiss="fetchCampaignAlerts")
    start-personalization-modal
    add-variant-to-experience-modal( @refetchExperiences="refetchExperiences" @addNewDynamicContentToExperience="addDynamicContentToExperience($event)")
    add-variant-to-campaign-modal( @createControlVariant="createControlVariant" @copyVariant="copyVariant($event)" @newVariant="newVariant")
    create-edit-goal(@goals:createGoal="createNewGoal")
</template>
<script>
  import moment from 'moment';
  import SmartABTestReporting from '@/components/SmartABTestReporting.vue';
  import COPY_VARIANT from '@/graphql/CopyVariant.gql';
  import CREATE_CONTROL_VARIANT from '@/graphql/CreateControlVariant.gql';
  import ActivateCampaignTooltip from '@/components/Campaign/ActivateCampaignTooltip.vue';
  import VariantTable from '@/components/VariantTable.vue';
  import SubscriberTable from '@/components/SubscriberTable.vue';
  import FeedbackStatistics from '@/components/FeedbackStatistics.vue';
  import ALL_VARIANTS from '@/graphql/AllVariants.gql';
  import GET_FEEDBACK_STATISTICS from '@/graphql/GetFeedbackStatistics.gql';
  import ALL_SUBSCRIBERS from '@/graphql/AllSubscribers.gql';
  import GET_CAMPAIGN_SETTINGS from '@/graphql/GetCampaignSettings.gql';
  import NEW_VARIANT from '@/graphql/NewVariant.gql';
  import CHANGE_CAMPAIGN_STATUS from '@/graphql/ChangeCampaignStatus.gql';
  import GET_DOMAINS_COUNT from '@/graphql/GetDomainsCount.gql';
  import RESTORE_CAMPAIGNS from '@/graphql/RestoreCampaigns.gql';
  import dateFormat from '@/mixins/dateFormat';
  import DomainManagement from '@/components/Modals/DomainManagement.vue';
  import { mapGetters, mapMutations, mapState, createNamespacedHelpers } from 'vuex';
  import { validateDomain, cleanDomain } from '@/util';
  import RENAME_DOMAIN from '@/graphql/RenameDomain.gql';
  import upgradePlanModal from '@/mixins/upgradePlanModal';
  import SettingsSummary from '@/components/SettingsSummary.vue';
  import GET_DOMAIN_USAGE_COUNT from '@/graphql/GetDomainUsageCount.gql';
  import Schedule from '@/components/Modals/Schedule.vue';
  import OldEditorTermination from '@/components/Modals/OldEditorTermination.vue';
  import EmbedCode from '@/components/Modals/EmbedCode.vue';
  import OldCampaignPreview from '@/components/Modals/OldCampaignPreview.vue';
  import StartPersonalizationModal from '@/components/Modals/StartPersonalization.vue';
  import AddVariantToExperienceModal from '@/components/Modals/AddVariantToExperience.vue';
  import AddVariantToCampaignModal from '@/components/Modals/AddVariantToCampaign.vue';
  import AlertBar from '@/components/AlertBar.vue';
  import VariantStatChart from '@/components/Charts/VariantStat.vue';
  import Highlight from '@/components/Highlight.vue';
  import EditableTitle from '@/components/EditableTitle.vue';
  import productFetch from '@/mixins/productFetch';
  import dynamicContent from '@/mixins/dynamicContent';
  import templateRecommendationMixin from '@/mixins/templateRecommendation';
  import ChevronDoubleDown from '@/components/Svg/Icons/ChevronDoubleDown.vue';
  import ChevronDoubleUp from '@/components/Svg/Icons/ChevronDoubleUp.vue';
  import { getBrandedClassString } from '@/components/Elements/Button';
  import campaignSetting from '@/mixins/campaignSetting';
  import campaignSchedule from '@/mixins/campaignSchedule';
  import { UilInfoCircle, UilCalender, UilPlusCircle, UilFileExport } from '@iconscout/vue-unicons';
  import AddFollowUp from '@/components/Modals/AddFollowUp';
  import AlertModal from '@/components/Campaign/AlertModal.vue';
  import GET_CAMPAIGN_ALERTS from '@/graphql/GetCampaignAlerts.gql';
  import GET_ACTIVE_CHANGES from '@/graphql/GetActiveChanges.gql';
  import GET_GOALS from '@/graphql/GetGoals.gql';
  import GET_VARIANT_SMART_AB_TEST_REPORT from '@/graphql/GetVariantSmartABTestReport.gql';
  import { campaignAlertByTypes } from '@/utils/campaignAlerts';
  import userAlert from '@/mixins/userAlert';
  import AlertHowToFix from '@/components/Campaign/AlertHowToFix';
  import cookie from '@/mixins/cookie';
  import BulkArchiveSvg from '@/assets/admin/svg/BulkArchiveSvg.vue';
  import ArrowClockwise from '@/assets/admin/svg/ArrowClockwise.vue';
  import ReIntegrationCampaignAlert from '@/components/ReIntegration/Alerts/Campaign.vue';
  import StartPersonalizedExperiences from '@/components/CampaignSettings/StartPersonalizedExperiences.vue';
  import Experiences from '@/components/Experiences/Experiences.vue';
  import campaignTable from '@/mixins/campaignTable';
  import isUsingExperiences from '@/mixins/isUsingExperiences';
  import experiencesMixin from '@/mixins/experiences';
  import CreateEditGoal from '@/components/Modals/CreateEditGoal.vue';
  import goalsMixin from '@/mixins/goals';
  import GET_GOAL from '@/graphql/GetGoal.gql';
  import { createDCUrl, createEmbeddedUrl } from '@/utils/pncURLBuilder';
  import campaignShareMixin from '@/mixins/campaignShare';
  import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue';
  import START_PERSONALIZED_EXPERIENCES from '@/graphql/StartPersonalizedExperiences.gql';
  import SET_PROFILE_KEY from '@/graphql/SetProfileKey.gql';
  import { track } from '@/services/xray';
  import CampaignPromotions from '@/components/Campaign/CampaignPromotions.vue';
  import GET_TOTAL_STATISTICS from '@/graphql/GetTotalStatistics.gql';
  import dateRange, { dateRangeDateFormat, getDateRangeOptions } from '@/mixins/dateRange';
  import calculatedCampaignData from '@/mixins/calculatedCampaignData';
  import CHECK_COUNTDOWN from '@/graphql/CheckCountdown.gql';

  const _clone = (v) => JSON.parse(JSON.stringify(v));

  const { mapGetters: paymentGetters } = createNamespacedHelpers('payment');

  const COOKIE_KEY_ACTIVATE_CAMPAIGN_POPOVER_SEEN = 'x-om-seen-activate-campaign-popover';
  const COOKIE_KEY_EXPERIENCE_INFO_MODAL = 'x-om-seen-experience-info-modal';
  const VISITOR_BASED_TZ_TYPE = 'visitor-based';
  const DEFAULT_INTERVAL_KEY = 'last30days';
  const DEFAULT_DATE_RANGE = getDateRangeOptions(dateRangeDateFormat).find(
    (range) => range.key === DEFAULT_INTERVAL_KEY,
  );
  const TTL = 1000 * 60 * 10;

  export default {
    components: {
      ArrowClockwise,
      BulkArchiveSvg,
      VariantTable,
      SubscriberTable,
      DomainManagement,
      FeedbackStatistics,
      SettingsSummary,
      Schedule,
      OldEditorTermination,
      EmbedCode,
      OldCampaignPreview,
      StartPersonalizationModal,
      AlertBar,
      VariantStatChart,
      Highlight,
      EditableTitle,
      ChevronDoubleDown,
      ChevronDoubleUp,
      UilInfoCircle,
      UilCalender,
      UilPlusCircle,
      UilFileExport,
      AddFollowUp,
      AlertModal,
      AlertHowToFix,
      ActivateCampaignTooltip,
      ReIntegrationCampaignAlert,
      AddVariantToExperienceModal,
      AddVariantToCampaignModal,
      StartPersonalizedExperiences,
      Experiences,
      CreateEditGoal,
      SmartABTestReporting,
      SkeletonLoader,
      CampaignPromotions,
    },
    mixins: [
      dateFormat,
      upgradePlanModal,
      productFetch,
      templateRecommendationMixin,
      campaignSetting,
      campaignSchedule,
      userAlert,
      cookie,
      campaignTable,
      dynamicContent,
      isUsingExperiences,
      experiencesMixin,
      goalsMixin,
      campaignShareMixin,
      dateRange,
      calculatedCampaignData,
    ],
    beforeRouteLeave(to, from, next) {
      const cookieKey = COOKIE_KEY_ACTIVATE_CAMPAIGN_POPOVER_SEEN;
      if (!this.getCookie(cookieKey)) {
        this.activateCampaignPopoverVisible = false;
        this.setActivateCampaignCookieSeen();
      }

      window.OMProductTour?.fn('close');
      next();
    },
    data() {
      return {
        smartABTestReport: null,
        columnClasses: {
          contact: 'brand-subscriber-table-contact',
          date: 'brand-subscriber-table-date',
        },
        activateCampaignPopoverVisible: false,
        campaignAlertByTypes,
        alerts: [],
        campaignId: this.$route.params.id,
        loadingVariantsData: true,
        loadingSmartABTestReport: true,
        subscribers: [],
        subscriberCount: 0,
        fields: [],
        sorting: { field: 'status', direction: 1 },
        pagination: { page: 1, limit: 10 },
        campaign: {
          name: '',
          device: '',
          domain: '',
          domainId: '',
          stopTestRunning: false,
          priority: 'HIGH',
          settings: {
            events: [],
            frontendRules: [],
            schedule: {
              from: null,
              to: null,
            },
          },
          integrations: [],
          positions: [],
        },
        popoverInstance: undefined,
        schedule: {
          from: moment(),
          to: moment().add(7, 'days'),
        },
        scheduleTime: {
          from:
            this.$i18n.locale === 'hu' ? { HH: '00', mm: '00' } : { hh: '01', mm: '00', a: 'am' },
          to: this.$i18n.locale === 'hu' ? { HH: '00', mm: '00' } : { hh: '01', mm: '00', a: 'am' },
        },
        exportableSubscribers: [],
        domainCount: 0,
        campaignName: '',
        newDomain: '',
        feedbackStatistics: [],
        firstLoad: true,
        openFirstExperience: false,
        hasActiveChanges: true,
        activeChanges: [],
        uniqueVariantIdWithSmartAbTest: null,
        goals: [],
        loadingGoals: false,
        loadingChanceToWin: false,
        goalConversions: {},
        followUpUseCaseId: '5fb534910e4fb2001328a153',
        totalStatistics: { stats: [] },
        chanceToWin: {},
        uplift: {},
        variantList: {},

        interval: {
          from: DEFAULT_DATE_RANGE.interval.from,
          to: DEFAULT_DATE_RANGE.interval.to,
        },
        selectedInterval: {
          key: DEFAULT_DATE_RANGE.key,
          value: DEFAULT_DATE_RANGE.value,
          interval: {
            from: DEFAULT_DATE_RANGE.interval.from,
            to: DEFAULT_DATE_RANGE.interval.to,
          },
        },
      };
    },

    computed: {
      ...mapState(['isCodeInserted', 'locale', 'showAdminLoader', 'account']),
      ...mapGetters([
        'preventSubscriberDataStorage',
        'currentPlan',
        'isActiveShopifyDomain',
        'isActiveShoprenterDomain',
        'isSubUser',
        'isSuperAdmin',
        'brandName',
        'hasAccountFeature',
        'wizardPreferences',
        'hasCreatedFollowup',
        'databaseId',
        'hasCampaignShareFeature',
        'getExportEmailAddress',
      ]),
      ...paymentGetters(['isPageViewBasedPackage']),
      isDefaultGoal() {
        return this.activeGoal?._id === 'defaultGoal';
      },
      isFirstCampaign() {
        return !!(this.$route.params.id === '1' && this.$route.params.new === 'new');
      },
      userId() {
        return this.$route.params.userId;
      },
      experiences() {
        return this.campaign?.experiences;
      },
      campaignHasSmartABTest() {
        return this.checkCampaignHasSmartABTests(this.activeChanges);
      },
      smartABTestSummary() {
        const allElements = this.smartABTestReport?.changes?.length;
        const runningElements = this.smartABTestReport?.changes?.filter(
          (obj) => obj.current !== null,
        )?.length;

        return {
          running: runningElements,
          all: allElements,
          uplift: this.smartABTestReport?.uplift,
        };
      },
      priorityButtonClass() {
        return this.locale === 'en' ? 'en-button' : 'hu-button';
      },

      isNew() {
        return this.campaign.version !== undefined && this.campaign.version !== 1;
      },
      isEnLocale() {
        return this.$i18n.locale === 'en';
      },
      allVariants() {
        let unsortedVariants = [];
        if (!this.variantList?.[this.dateKey]?.variants) return [];

        if (this.activeGoal?._id === 'defaultGoal') {
          unsortedVariants = this.getVariantsForDefaultConversion();
        } else {
          unsortedVariants = this.getVariantsForGoal();
        }

        if (this.isUsingExperiences) return unsortedVariants;

        return unsortedVariants.sort(this.sortByField);
      },
      variants() {
        const { page, limit } = this.pagination;
        const start = page > 0 ? (page - 1) * limit : 0;
        return this.allVariants.slice(start, start + limit);
      },
      showDeleteVariantMenu() {
        const normalVariants = this.allVariants.filter((variant) => !variant.isControlVariant);
        return normalVariants.length > 1;
      },
      variantCount() {
        return this.variantList?.[this.dateKey]?.variants?.length ?? 0;
      },
      campaignSettings() {
        return this.campaign.settings;
      },
      leadFileName() {
        const format = `${this.dateFormat}_${this.timeFormat}`;
        return `leads_${moment().format(format)}`;
      },
      localizedTimeFormat() {
        return this.$i18n.locale === 'hu' ? 'HH:mm' : 'hh:mm a';
      },
      scheduleStr() {
        const schedule = this.campaign.schedule;
        if (schedule && schedule.from) {
          const from = moment(schedule.from).format(`${this.dateFormat}`);
          let to = moment(schedule.to).format(`${this.dateFormat}`);

          if (!schedule.to) {
            to = '';
          }
          return !this.isScheduleExpired()
            ? { expired: false, dateTime: { from: `${from}`, to: `${to}` } }
            : { expired: true, dateTime: { from: `${from}`, to: `${to}` } };
        }

        return { expired: true, dateTime: { from: null, to: null } };
      },
      getRepeatsOnDays() {
        const schedule = this.campaign.schedule;
        const days = [
          this.$t('daysShort.sunday'),
          this.$t('daysShort.monday'),
          this.$t('daysShort.tuesday'),
          this.$t('daysShort.wednesday'),
          this.$t('daysShort.thursday'),
          this.$t('daysShort.friday'),
          this.$t('daysShort.saturday'),
        ];
        let repeatsOn = '';
        if (schedule.repeatsOn && schedule.repeatsOn.length) {
          const repeatsOnElement = _clone(schedule.repeatsOn[0]);
          if (schedule.repeatsOn[0].fromTime) {
            if (repeatsOnElement.daysOfWeek.length && repeatsOnElement.daysOfWeek[0] === 0) {
              repeatsOnElement.daysOfWeek.shift();
              repeatsOnElement.daysOfWeek[repeatsOnElement.daysOfWeek.length] = 0;
            }

            const daysOfWeek = repeatsOnElement.daysOfWeek.map((day) => days[day]);
            repeatsOn += daysOfWeek.join(', ');
            if (repeatsOn && repeatsOnElement.fromTime && repeatsOnElement.toTime) {
              const fromTimeString = this.isEnLocale
                ? ` ${moment(repeatsOnElement.fromTime, ['HH:mm a']).format('hh:mm a')}`
                : ` ${repeatsOnElement.fromTime}`;
              const toTimeString = this.isEnLocale
                ? ` to ${moment(repeatsOnElement.toTime, ['HH:mm a']).format('hh:mm a')}`
                : ` - ${repeatsOnElement.toTime}`;
              repeatsOn += fromTimeString;
              repeatsOn += toTimeString;
            }
          }
        }
        return repeatsOn;
      },
      getRepeatsOnTime() {
        const schedule = this.campaign.schedule;
        let repeatsOn = '';
        if (!schedule.repeatsOn || !schedule.repeatsOn.length) {
          return repeatsOn;
        }

        const repeatsOnElement = _clone(schedule.repeatsOn[0]);
        const timeFormat = this.localizedTimeFormat;
        if (repeatsOnElement.fromTime || repeatsOnElement.toTime) {
          if (repeatsOnElement.fromTime) {
            repeatsOn += `${this.$t('dateTime.dateFrom')} ${moment(
              repeatsOnElement.fromTime,
              'HH:mm',
            ).format(timeFormat)}`;
          }

          if (repeatsOnElement.toTime) {
            repeatsOn += ` ${this.$t('dateTime.dateTo')} ${moment(
              repeatsOnElement.toTime,
              'HH:mm',
            ).format(timeFormat)}`;
          }
        }
        return repeatsOn;
      },
      activeVariants() {
        return this.variants.filter((v) => v.status === 'active').length;
      },
      eligibleForTestRunning() {
        return this.variants.filter((v) => v.status === 'active' && !v.isControlVariant).length > 1;
      },
      hasNoEmbedPoisitions() {
        return (
          this.campaign &&
          (!this.campaign.positions || !this.campaign.positions.length) &&
          !this.campaign.manuallyEmbedded
        );
      },
      isEmbedded() {
        return (
          this.campaign && this.campaign.templateType && this.campaign.templateType === 'embedded'
        );
      },
      isScheduleCurrentlyActive() {
        const currentCampaignSchedule = this.campaign.schedule;
        if (!currentCampaignSchedule) return false;

        if (currentCampaignSchedule.from && currentCampaignSchedule.to) {
          return moment(new Date()).isBetween(
            currentCampaignSchedule.from,
            currentCampaignSchedule.to,
            undefined,
            '[]',
          );
        }
        if (currentCampaignSchedule.from) {
          return moment(new Date()) >= moment(currentCampaignSchedule.from);
        }
        return false;
      },
      hasCouponInCampaign() {
        return this.campaign?.hasCoupon;
      },
      alertColor() {
        const hasError = this.alerts.some((alert) =>
          this.campaignAlertByTypes.error.includes(alert.type),
        );
        return hasError ? 'danger' : 'warning';
      },
      experimentInfo() {
        let experimentInfoType = 'campaignVariantsInRunningExperiment';
        if (this.campaign.status === 'inactive') {
          experimentInfoType = 'campaignVariantsInDraftExperiment';
        }
        return this.$t(`experiments.${experimentInfoType}`, {
          experimentName: this.campaign.currentExperimentName,
          experimentLink: `/${this.userId}/experiment/${this.campaign.currentExperimentId}`,
        });
      },
      toggleButtonStatus() {
        return this.campaign.currentExperimentName ||
          (!this.scheduleStr.expired && this.campaign.status !== 'inactive')
          ? 'pointer-events:none'
          : '';
      },
      toggleButtonClasses() {
        return {
          inactive:
            this.campaign.status === 'inactive'
              ? 'inactive campaign-activate'
              : !this.scheduleStr.expired,
          'in-experiment': this.campaign.currentExperimentName,
        };
      },
      activeGoal: {
        get() {
          const defaultConversionGoal = _clone(this.defaultGoal);

          if (this.selectedGoal) {
            return this.selectedGoal;
          }
          const defaultFromLs = this.getDefaultGoalFromLS(
            this.campaign._id,
            this.DEFAULT_GOAL_BY_CAMPAIGN_KEY,
          );
          if (defaultFromLs) {
            const lastViewedGoal = this.goals.find((goal) => goal._id === defaultFromLs);
            if (lastViewedGoal) return lastViewedGoal;
          }
          if (this.isDynamicContent) return this.goals?.[0];

          return defaultConversionGoal;
        },
        set(goal) {
          this.selectedGoal = goal;
        },
      },
      countActiveCampaigns() {
        return this.variants.filter((v) => v.status === 'active').length;
      },
      followUpPromoVisible() {
        return this.hasCouponInCampaign && !this.hasCreatedFollowup;
      },
      experiencesPromoVisible() {
        return !this.isUsingExperiences && !this.campaignHasSmartABTest;
      },
      smartPopupPromoVisible() {
        if (this.isDynamicContent) return false;
        if (
          this.campaign.hasProductElement ||
          this.campaign.hasEmailField ||
          this.campaign.hasPhoneField
        ) {
          const { stats } = this.totalStatistics;
          const impressions = stats.find(({ type }) => type === 'impressions');
          const conversions = stats.find(({ type }) => type === 'conversions');

          return impressions?.total >= 5000 || conversions?.total >= 200;
        }

        return false;
      },
      dateKey() {
        return `${this.interval.from}-${this.interval.to}`;
      },
    },

    watch: {
      'activeGoal._id': async function (activeGoal) {
        if (activeGoal && activeGoal !== 'defaultGoal') {
          await this.createMaterializedViewIfNecessary(
            activeGoal,
            this.goals,
            this.campaign?.domainId,
          );

          await this.getGoal({
            goalId: activeGoal,
            campaignId: this.campaignID,
            domainId: this.campaign.domainId,
          });
        }
      },
      async selectedGoal(selectedGoal) {
        this.saveDefaultGoalToLS(
          selectedGoal._id,
          this.campaign._id,
          this.DEFAULT_GOAL_BY_CAMPAIGN_KEY,
        );
        if (selectedGoal?._id === 'defaultGoal') {
          this.refetchVariants();
        }
      },
      'campaign.domain': function (newVal, oldVal) {
        this.newDomain = this.campaign.domain;
        if (!this.firstLoad && oldVal !== newVal) {
          this.changeCampaignSetting(
            'domain',
            newVal,
            this.campaignId,
            this.fetchCampaign.bind(this, false),
          );
        }
        const loggedInIndex = this.campaign.frontendRules.findIndex((r) => r.type === 'loggedIn');
        if (loggedInIndex !== -1 && !this.isShopifyDomain(newVal)) {
          const newValue = _clone(this.campaign.frontendRules);
          newValue.splice(loggedInIndex, 1);
          this.changeCampaignSetting(
            'frontendRules',
            newValue,
            this.campaignId,
            this.fetchCampaign.bind(this, false),
          );
        }
      },
      'campaign.domainId': async function (newVal, oldVal) {
        if (!this.firstLoad && oldVal !== newVal) {
          await this.changeCampaignSetting(
            'domainId',
            newVal,
            this.campaignId,
            this.fetchCampaign.bind(this, false),
          );
          await this.refetchVariants();
          this.alerts = [];
          this.fetchCampaignAlerts();
        }

        if (!this.firstLoad) {
          this.refreshGoalsMaterialisedView(this.campaign.domainId);
        }
        this.getGoals();
      },
      'campaign.status': function (status) {
        if (this.isScheduleExpired()) {
          this.campaign.status = 'inactive';
        }
        if (status === 'active' && this.isFirstCampaign) {
          this.setActivateCampaignCookieSeen();
          this.activateCampaignPopoverVisible = false;
        }
      },
      showAdminLoader(value) {
        if (this.openFirstExperience && !value) {
          this.$nextTick(() => {
            this.$refs?.experiences?.openAccordion?.(0);
          });
        }

        if (!this.account?.profile?.firstExperience && this.campaign?.experiences?.length) {
          this.$nextTick(() => {
            this.$refs?.experiences?.openAccordion?.(0);
            this.$refs?.experiences?.openAccordion?.(1);
          });
        }
      },
    },

    async created() {
      await this.fetchCampaign();

      if (this.campaign.experiences?.length > 0) {
        this.pagination.page = 0;
      }

      await this.fetchCampaignAlerts();
      if (!this.campaign.domain && !this.campaign.domainId) {
        this.$modal.show('domain-management');
      }

      if (this.hasVisitorCartRevampRule()) {
        const hasDomain = this.campaign.domain != null && this.campaign.domain !== '';
        if (hasDomain && this.isActiveShopifyDomain(this.campaign.domain)) {
          const productDetails = await this.getShopifyProductDetails();
          if (productDetails && productDetails.length) {
            this.mergeProductDetailsWithSavedRules(productDetails, 'shopify');
          }
        }
        if (hasDomain && this.isActiveShoprenterDomain(this.campaign.domain)) {
          const productDetails = await this.getShoprenterProductDetails();
          if (productDetails && productDetails.length) {
            this.mergeProductDetailsWithSavedRules(productDetails, 'shoprenter');
          }
        }
      }

      this.firstLoad = false;

      this.fetchSubscribers();
      this.fetchFeedbackStatistics();
      this.checkActivateCampaignPopover();

      await this.getVariantList();
      await this.processActiveDCChanges();
      await this.getSmartABTestReport();
      this.refreshGoalsMaterialisedView(this.campaign.domainId);
      this.getGoals();
      this.getTotalStatistics();
    },
    methods: {
      ...mapMutations({ mutateShowAdminLoader: 'showAdminLoader' }),
      async getTotalStatistics() {
        const { data } = await this.$apollo.query({
          query: GET_TOTAL_STATISTICS,

          variables: {
            campaignId: this.campaignID,
            interval: {
              from: moment().subtract(30, 'days').format('YYYY-MM-DD'),
              to: moment().format('YYYY-MM-DD'),
            },
            includeVariantData: false,
          },
        });
        if (data) {
          this.totalStatistics = data.totalStatistics;
        }
      },
      variantsNotLoaded(variantList, date) {
        return !variantList?.[date] || variantList[date]?.expire < Date.now();
      },
      async getVariantList({ forceLoad } = {}) {
        this.loadingVariantsData = true;
        if (forceLoad || this.variantsNotLoaded(this.variantList, this.dateKey)) {
          try {
            const { data } = await this.$apollo.query({
              query: ALL_VARIANTS,
              variables: {
                campaignId: this.campaignId,
                interval: {
                  from: this.interval.from,
                  to: this.interval.to,
                },
              },
            });

            if (data) {
              this.$set(this.variantList, this.dateKey, {
                variants: data.variantsData?.variants,
                expire: Date.now() + TTL,
              });

              const chanceToWinVariants = this.getChanceToWinVariants(
                {
                  variants: this.variantList[this.dateKey].variants.map((v) => {
                    return { ...v, variantId: v._id };
                  }),
                },
                'default',
              );

              if (!this.isUsingExperiences && this.activeGoal._id === 'defaultGoal') {
                this.getChanceToWin(chanceToWinVariants, 'defaultGoal', this.dateKey);
                const baseline = this.getBaselineVariant(
                  this.variantList[this.dateKey].variants,
                  this.activeGoal._id,
                );
                this.getUplift({
                  variants: this.variantList[this.dateKey].variants,
                  baseline,
                  date: this.dateKey,
                  goalId: 'defaultGoal',
                });
              }
            }
          } catch (e) {
            console.error('err', e);
          }
        }

        this.loadingVariantsData = false;
      },
      setSelectedGoal(event) {
        this.selectedGoal = event;
      },
      getConversionRate(sub, imp) {
        return sub && imp ? ((sub / imp) * 100).toFixed(2) : 0;
      },
      changeExperienceName({ name, index }) {
        this.campaign.experiences[index].name = name;
      },
      changeVariantName() {
        this.refetchVariants();
      },
      refetchExperiences({ openFirst = false } = {}) {
        this.loadingVariantsData = true;
        this.openFirstExperience = openFirst;
        this.fetchCampaign();
        this.refetchVariants();
      },
      async onStartPersonalizedExperienceEvent() {
        this.openFirstExperience = true;
        await this.initPersonalizedExperiences();
        await this.fetchCampaign();
        const modalSeenCookieKey = `${COOKIE_KEY_EXPERIENCE_INFO_MODAL}_${this.databaseId}`;
        const seenModal = this.getCookie(modalSeenCookieKey);
        if (!seenModal) {
          this.setCookie(modalSeenCookieKey, '1');
          setTimeout(() => {
            this.$modal.show('start-personalization');
          }, 1000);
        }
      },
      async initPersonalizedExperiences() {
        await this.$apollo.mutate({
          mutation: START_PERSONALIZED_EXPERIENCES,
          variables: {
            campaign: this.campaign._id,
          },
        });

        if (!this.account?.profile?.firstExperience) {
          await this.$apollo.mutate({
            mutation: SET_PROFILE_KEY,
            variables: { key: 'firstExperience', value: new Date() },
          });
        }
      },
      checkActivateCampaignPopover() {
        const status = this.campaign.status;
        const cookieKey = COOKIE_KEY_ACTIVATE_CAMPAIGN_POPOVER_SEEN;
        if (this.getCookie(cookieKey) || status === 'active') {
          this.activateCampaignPopoverVisible = false;
        } else {
          this.showActivateCampaignPopover();
        }
      },
      async processActiveDCChanges() {
        if (!this.isDynamicContent) return;
        const campaignId = this.$route.params.id;
        if (!campaignId) return;

        const {
          data: { changes },
        } = await this.$apollo.query({
          query: GET_ACTIVE_CHANGES,
          variables: {
            campaignId,
          },
        });

        this.activeChanges = changes;

        const variantIdsWithChange = changes.map(({ variantId }) => variantId);

        this.hasActiveChanges = variantIdsWithChange.some((variantId) =>
          this.variants.some((variant) => variant.status === 'active' && variant._id === variantId),
        );

        const activeVariantIds = this.variants
          .filter((item) => item.status === 'active')
          .map(({ _id }) => _id);

        const variantIdsWithSmartABTest = this.getVariantIdsWithSmartABTestChanges(changes);

        const activeVariantIdsWithSmartABTest = variantIdsWithSmartABTest.filter((value) =>
          activeVariantIds.includes(value),
        );

        if (activeVariantIdsWithSmartABTest.length === 1) {
          this.uniqueVariantIdWithSmartAbTest = activeVariantIdsWithSmartABTest[0];
        }
      },
      checkCampaignHasSmartABTests(dcVariants) {
        return dcVariants.some((variant) => {
          return variant.changes.some((change) => change.type === 'smart-ab-test');
        });
      },
      getVariantIdsWithSmartABTestChanges(changes) {
        return changes.reduce((accumulator, change) => {
          const hasAbTest = change.changes.some((item) => item.type === 'smart-ab-test');
          if (hasAbTest) {
            accumulator.push(change.variantId);
          }
          return accumulator;
        }, []);
      },
      setActivateCampaignCookieSeen() {
        const cookieKey = COOKIE_KEY_ACTIVATE_CAMPAIGN_POPOVER_SEEN;
        this.setCookie(cookieKey, 1, 60); // expire after 60 days
      },
      showActivateCampaignPopover() {
        this.activateCampaignPopoverVisible = true;
      },
      closeActivateCampaignPopover() {
        this.setActivateCampaignCookieSeen();
        this.activateCampaignPopoverVisible = false;
      },
      async fetchCampaignAlerts() {
        const campaignId = this.campaign._id;
        if (!campaignId) return;
        const {
          data: { alerts },
        } = await this.$apollo.query({
          query: GET_CAMPAIGN_ALERTS,
          variables: {
            campaignId,
          },
        });
        this.alerts = alerts;
      },
      getUpliftAndChanceToWinForGoals({ goal }) {
        const chanceToWinVariants = this.getChanceToWinVariants(
          {
            variants: this.variantList[this.dateKey].variants.map((v) => {
              const goalData = goal.variants.find((goalVariant) => goalVariant.variantId === v._id);
              if (goalData) return goalData;
              return { variantId: v._id, impressions: 0, conversions: 0 };
            }),
          },
          'goal',
        );
        this.getChanceToWin(chanceToWinVariants, this.activeGoal._id, this.dateKey);
        const baseline = this.getBaselineVariant(
          this.variantList[this.dateKey].variants,
          this.activeGoal._id,
        );
        this.getUplift({
          variants: goal.variants,
          baseline,
          date: this.dateKey,
          goalId: this.activeGoal._id,
        });
      },
      async refetchVariants() {
        await this.getVariantList({ forceLoad: true }).then(this.processActiveDCChanges);
        this.fetchFeedbackStatistics();

        if (this.activeGoal._id !== 'defaultGoal') {
          const goal = this.goalConversions?.[this.activeGoal._id]?.[this.dateKey]?.goal;
          this.getUpliftAndChanceToWinForGoals({ goal });
        }

        if (this.isOnWrongPage(this.allVariants)) {
          this.pagination.page = this.pagination.page > 0 ? this.pagination.page - 1 : 0;
        }
      },
      addNewVariant() {
        return this.$apollo.mutate({
          mutation: NEW_VARIANT,
          variables: { input: { _id: this.campaignId } },
        });
      },
      campaignHasControlVariant() {
        const controlVariant = this.variants.filter((variant) => {
          return !!variant?.isControlVariant;
        });

        return controlVariant.length;
      },
      variantsWithoutControl() {
        return this.variants.filter((variant) => {
          return !variant?.isControlVariant;
        });
      },
      handleAddNewVariant() {
        if (
          this.campaign.templateType !== 'dynamic_content' &&
          this.campaignHasControlVariant() &&
          this.allVariants.length === 2
        ) {
          const { _id } = this.variantsWithoutControl()[0];
          this.copyVariant({ variantId: _id });
        } else {
          this.openAddNewVariantModal();
        }
      },
      openAddNewVariantModal() {
        this.$modal.show('add-variant-to-campaign', {
          campaign: this.campaign,
          variants: this.allVariants,
        });
      },
      async createControlVariant() {
        try {
          this.loadingVariantsData = true;
          await this.$apollo
            .mutate({
              mutation: CREATE_CONTROL_VARIANT,
              variables: {
                campaignId: this.campaign._id,
              },
            })
            .then(() => {
              this.refetchVariants();
            });
        } catch (e) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.controlVariantAddError'),
          });
          this.refetchVariants();
        }
      },

      async copyVariant(event) {
        try {
          this.loadingVariantsData = true;
          const { variantId } = event;
          await this.$apollo
            .mutate({
              mutation: COPY_VARIANT,
              variables: {
                variantId,
                templateType: this.campaign.templateType,
              },
            })
            .then(() => {
              this.refetchVariants();
            });
        } catch (e) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.copyVariantError'),
          });
          this.refetchVariants();
        }
      },
      async newVariant() {
        if (this.loadingVariantsData) return;
        this.loadingVariantsData = true;

        await this.addNewVariant();
        await this.refetchVariants();

        const lastVariant = this.variants.reduce((r, a) => {
          return r.createdAt > a.createdAt ? r : a;
        });

        if (this.isDynamicContent) {
          window.location.href = this.$refs.variantTable.getEditorLink(lastVariant);
        }

        if (this.variantList?.variants?.[this.dateKey]?.length > this.pagination.limit) {
          this.pagination.page = 1;
        }
      },
      isScheduleExpired() {
        if (!this.campaign.schedule) return false;

        if (this.campaign.schedule.to) {
          if (this.campaign.schedule.tz === VISITOR_BASED_TZ_TYPE) {
            return moment.utc(this.campaign.schedule.to).add(1, 'day') < new Date();
          }
          return new Date(this.campaign.schedule.to) < new Date();
        }
      },
      async toggleCampaignStatus(status) {
        if (status === 'active' && !this.campaign.domain && !this.campaign.domainId) {
          this.$modal.show('domain-management');
          this.$refs.statusToggle.toggle();
          return;
        }

        const {
          data: { changeCampaignStatus: result },
        } = await this.$apollo.mutate({
          mutation: CHANGE_CAMPAIGN_STATUS,
          variables: {
            campaignId: this.campaignId,
            status,
          },
        });
        if (status === 'active') {
          this.$apollo
            .mutate({
              mutation: CHECK_COUNTDOWN,
              variables: {
                campaignId: this.campaignId,
              },
            })
            .then(
              ({
                data: {
                  checkCountdown: { countdownInFuture },
                },
              }) => {
                if (countdownInFuture) {
                  this.fetchCampaignAlerts();
                }
              },
            );
        }

        if (result.message === 'upgrade') {
          if (!this.isPageViewBasedPackage) {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.activeDomainLimitReached'),
            });
            return;
          }

          this.showUpgradePlanModal(result.triggers);
        } else if (result.message) {
          this.$notify({
            type: 'error',
            text: this.$t(`${result.message}`),
          });
        }
        this.fetchCampaign(false);
      },
      createDateTime(date, time) {
        const dateStr = moment(date).format('YYYY-MM-DD');
        const timeStr = this.timeToStr(time);
        const final = moment(
          `${dateStr} ${timeStr}`,
          this.$i18n.locale === 'hu' ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD hh:mm a',
        ).toDate();
        return final;
      },
      timeToStr(obj) {
        return this.$i18n.locale === 'hu' ? `${obj.HH}:${obj.mm}` : `${obj.hh}:${obj.mm} ${obj.a}`;
      },
      strToTime(str, format) {
        const timeStr = moment(str).format(format);
        if (format === 'hh:mm a') {
          const [time, a] = timeStr.split(' ');
          const [hh, mm] = time.split(':');

          return [hh, mm, a];
        }
        const [HH, mm] = timeStr.split(':');

        return [HH, mm];
      },

      deleteScheduleFromVariants() {
        this.deleteSchedule(this.fetchCampaign); // from campaignSchedule mixin
      },
      async restoreCampaigns() {
        try {
          await this.$apollo.query({
            query: RESTORE_CAMPAIGNS,
            variables: {
              campaignIds: [this.campaign._id],
            },
          });
          this.$notify({
            type: 'success',
            text: this.$t('notifications.campaignRestore.restoreSuccess'),
          });
          track('campaignsRestored', { ids: [this.campaign._id], count: 1 });
        } catch (e) {
          this.$notify({
            type: 'danger',
            text: this.$t('notifications.campaignRestore.restoreFailed'),
          });
        }

        await this.fetchCampaign();
      },
      async fetchSubscribers() {
        const { data } = await this.$apollo.query({
          query: ALL_SUBSCRIBERS,
          variables: {
            pagination: { page: 1, limit: 10 },
            filter: {
              campaignIds: [this.campaignId],
              domainIds: [],
            },
          },
        });
        const { fields, subscribers } = data.allSubscribers;
        this.subscribers = subscribers;
        this.fields = fields;
      },
      async fetchFeedbackStatistics() {
        const {
          data: { feedbackStatistics },
        } = await this.$apollo.query({
          query: GET_FEEDBACK_STATISTICS,
          variables: {
            campaignId: this.campaign._id,
          },
        });
        this.feedbackStatistics = feedbackStatistics;
      },

      exportLeads(type) {
        this.$refs.exportPopper.doClose();

        const filter = {
          campaignIds: [this.campaignId],
          domainIds: [],
        };

        let email = this.getExportEmailAddress;
        // superadmin can send to different email address for testing
        if (this.isSuperAdmin) {
          // eslint-disable-next-line no-alert
          email = window.prompt('Where to send superadmin email?', email);
          if (email == null || email.trim().length < 4) {
            throw new Error('email is empty');
          }
        }

        this.$axios
          .get('export/subscribers', {
            params: {
              filter,
              type,
              email,
            },
          })
          .then(() => {
            this.$notify({
              type: 'success',
              duration: 10000,
              text: this.$t('notifications.leads.exportSuccess', { email }),
            });
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.leads.exportError'),
            });
          });
      },

      setScheduleFromCampaign() {
        if (this.campaign.schedule && this.campaign.schedule.from && this.campaign.schedule.to) {
          this.schedule.from = moment(this.campaign.schedule.from);
          this.schedule.to = moment(this.campaign.schedule.to);
          if (this.$i18n.locale === 'hu') {
            const [fromHH, frommm] = this.strToTime(this.campaign.schedule.from, 'HH:mm');
            const [toHH, tomm] = this.strToTime(this.campaign.schedule.to, 'HH:mm');
            this.scheduleTime = {
              from: { HH: fromHH, mm: frommm },
              to: { HH: toHH, mm: tomm },
            };
          } else {
            const [fromhh, frommm, froma] = this.strToTime(this.campaign.schedule.from, 'hh:mm a');
            const [tohh, tomm, toa] = this.strToTime(this.campaign.schedule.to, 'hh:mm a');
            this.scheduleTime = {
              from: { hh: fromhh, mm: frommm, a: froma },
              to: { hh: tohh, mm: tomm, a: toa },
            };
          }
        } else {
          this.schedule = {
            from: moment(),
            to: moment().add(7, 'days'),
          };
          this.scheduleTime = {
            from:
              this.$i18n.locale === 'hu' ? { HH: '00', mm: '00' } : { hh: '01', mm: '00', a: 'am' },
            to:
              this.$i18n.locale === 'hu' ? { HH: '00', mm: '00' } : { hh: '01', mm: '00', a: 'am' },
          };
        }
      },
      async fetchCampaign(showLoader = true) {
        if (showLoader) {
          this.mutateShowAdminLoader(true);
        }

        try {
          const {
            data: { campaign },
          } = await this.$apollo.query({
            query: GET_CAMPAIGN_SETTINGS,
            variables: {
              campaignId: this.campaignId,
            },
          });

          this.campaign = _clone(campaign);
          this.setScheduleFromCampaign();
          this.campaign.frontendRules.map((r, i) => {
            if (['cookie', 'visitorAttribute'].includes(r.type)) {
              if (!r.options.expressions) {
                r = {
                  type: r.type,
                  options: {
                    expressions: [r.options.expression],
                  },
                };
              }
              this.campaign.frontendRules[i] = r;
            }
            return r;
          });
          this.newDomain = this.campaign.domain;
          this.campaignName = this.campaign.name;
        } catch (e) {
          console.error(e);
        }

        if (showLoader) {
          this.mutateShowAdminLoader(false);
        }
      },

      async renameCampaign(newCampaignName) {
        if (!newCampaignName.length) {
          return;
        }

        try {
          await this.changeCampaignSetting(
            'name',
            newCampaignName,
            this.campaignId,
            this.fetchCampaign.bind(this, false),
          );

          this.campaign.name = newCampaignName;
          this.campaignName = this.campaign.name;

          this.$notify({
            type: 'success',
            text: this.$t('notifications.renameSuccess'),
          });
        } catch (e) {
          console.log(e);
          this.campaignName = this.campaign.name;
          this.$notify({
            type: 'error',
            text: this.$t('notifications.renameError'),
          });
        }
      },

      async renameDomain() {
        if (this.campaign.domain !== this.newDomain) {
          if (!validateDomain(this.newDomain)) {
            this.$notify({
              type: 'error',
              text: this.$t('validations.domain.invalid'),
            });
            this.newDomain = this.campaign.domain;
            return;
          }

          const {
            data: { domainUsageCount },
          } = await this.$apollo.query({
            query: GET_DOMAIN_USAGE_COUNT,
            variables: {
              domainId: this.campaign.domainId,
            },
          });

          if (domainUsageCount > 1) {
            this.$modal.show('dialog', {
              title: this.$t('domainUsedRename', { count: domainUsageCount }),
              buttons: [
                {
                  title: this.$t('yes'),
                  class: getBrandedClassString({ primary: true }, 'mr-3'),
                  handler: () => this.rename(),
                },
                {
                  title: this.$t('cancel'),
                  class: getBrandedClassString({ secondary: true }),
                  handler: () => {
                    this.newDomain = this.campaign.domain;
                    this.$modal.hide('dialog');
                  },
                },
              ],
            });
          } else {
            this.rename();
          }
        }
      },
      async rename() {
        const domainWithoutProtocol = cleanDomain(this.newDomain);

        const {
          data: { renameDomain },
        } = await this.$apollo.mutate({
          mutation: RENAME_DOMAIN,
          variables: {
            domainId: this.campaign.domainId,
            domain: domainWithoutProtocol,
          },
        });

        if (renameDomain) {
          this.campaign.domain = domainWithoutProtocol;
          this.newDomain = this.campaign.domain; // sure??
          this.$refs.dynamicDomainInput.blur();
          this.$notify({
            type: 'success',
            text: this.$t('notifications.renameSuccess'),
          });
          this.$modal.hide('dialog');
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.renameError'),
          });
        }
      },
      onDomainRefetch() {
        this.$apollo.queries.domainCount.refetch();
      },
      openWebSelector() {
        const url = createEmbeddedUrl({
          domain: this.campaign.domain,
          campaignId: parseInt(this.$route.params.id, 10),
          locale: this.$i18n.locale,
          returnUrl: this.$route.path,
          positions: this.campaign.positions,
        });
        window.open(url);
      },
      async confirmManuallyEmbedded() {
        this.$modal.hide('embed-code');
        await this.$axios.post(`/web-selector/confirm-manually-embedded`, {
          campaignId: this.$route.params.id,
        });
        this.campaign.manuallyEmbedded = true;
        this.campaign.positions = null;
      },

      hasVisitorCartRevampRule() {
        return this.campaign.frontendRules.find((rule) => rule.type === 'visitorCartRevamp');
      },
      openFollowupExplanation() {
        this.$modal.show('add-follow-up');
      },
      cancel() {
        this.newDomain = this.campaign.domain;
        this.$refs.dynamicDomainInput.blur();
      },
      updateExperiencesOrder(experiences) {
        this.campaign.experiences = experiences;
      },
      updateVariantStatus() {
        this.refetchVariants();
      },
      getDynamicContentEditLink(variant) {
        const { id } = this.$route.params;

        const returnUrl = `/${this.userId}/campaign/${id}`;

        return createDCUrl({
          domain: this.campaign.domain,
          campaignId: parseInt(id, 10),
          locale: this.$i18n.locale,
          variantId: variant._id,
          returnUrl,
          backUrl: returnUrl,
          isSuperAdmin: this.isSuperAdmin,
        });
      },
      async addDynamicContentToExperience({ experienceId }) {
        await this.refetchVariants();

        const lastVariant = this.variants.reduce((r, a) => {
          return r.createdAt > a.createdAt ? r : a;
        });

        await this._addVariantToExperience(lastVariant, experienceId, true);

        window.location.href = this.getDynamicContentEditLink(lastVariant);
      },
      async getSmartABTestReport() {
        if (!this.campaignHasSmartABTest && !this.uniqueVariantIdWithSmartAbTest) {
          return;
        }

        const smartABTEstVariantId = this.uniqueVariantIdWithSmartAbTest;
        const {
          data: { getVariantSmartABTestReport },
        } = await this.$apollo.query({
          query: GET_VARIANT_SMART_AB_TEST_REPORT,
          variables: { variantId: smartABTEstVariantId },
        });

        this.smartABTestReport = getVariantSmartABTestReport;

        this.loadingSmartABTestReport = false;
      },
      async getGoals() {
        const {
          data: { goals },
        } = await this.$apollo.query({
          query: GET_GOALS,
          variables: {
            domainId: this.campaign.domainId,
            campaignId: parseInt(this.campaignId, 10),
            includeRules: false,
          },
        });
        this.goals = [...goals.goals, ...(this.isDynamicContent ? [] : [this.defaultGoal])];
      },
      async createNewGoal(goal) {
        await this.upsertGoal(goal);
        if (this.campaign.domainId === goal.domainId) {
          this.setSelectedGoalToNew();
        }
      },
      async getGoal({ goalId, campaignId, isDefault, domainId }) {
        this.loadingGoals = true;

        const goalDataMissing = this.goalNotInLocalCache(goalId, this.dateKey);
        if (goalDataMissing && goalId !== 'defaultGoal') {
          const {
            data: { goal },
          } = await this.$apollo.query({
            query: GET_GOAL,
            variables: {
              goalId,
              campaignId,
              isDefault,
              domainId,
              ...(this.selectedInterval.key !== 'allTime'
                ? {
                    startDate: this.interval.from,
                    endDate: this.interval.to,
                  }
                : {}),
            },
          });
          const goalVariantsWithConversionRate = goal?.variants.map((variant) => {
            return {
              ...variant,
              _id: variant.variantId,
              conversions: variant.goalCount,
              visitors: variant.uniqueVisitorCount,
              conversionRate: variant.uniqueVisitorCount
                ? variant.goalCount / variant.uniqueVisitorCount
                : null,
            };
          });
          this.$set(this.goalConversions, goalId, {
            ...this.goalConversions[goalId],
            [this.dateKey]: {
              goal: { ...goal, variants: goalVariantsWithConversionRate },
              expire: Date.now() + this.GOAL_TTL,
            },
          });

          if (!this.isUsingExperiences) {
            this.getUpliftAndChanceToWinForGoals({
              goal: { ...goal, variants: goalVariantsWithConversionRate },
            });
          }
        }
        this.loadingGoals = false;
      },

      async shareCampaign() {
        await this._shareCampaign(Number(this.campaignId));
      },
      followUpPromoClick() {
        this.$router.push({ name: 'use_case_templates', params: { id: this.followUpUseCaseId } });
      },
      setInterval(event) {
        if (event.key === 'allTime') {
          this.interval = { from: '0001-01-01', to: moment().format(this.dateRangeDateFormat) };
        } else {
          const from = moment(event.interval.from).format(this.dateRangeDateFormat);
          const to = moment(event.interval.to).format(this.dateRangeDateFormat);
          this.interval = { from, to };
        }

        this.selectedInterval = event;
        this.getVariantList();
        this.getGoal({
          goalId: this.activeGoal._id,
          campaignId: this.campaignID,
          domainId: this.campaign.domainId,
        });
      },
      sortByField(variantA, variantB) {
        const { field, direction } = this.sorting;

        if (field === 'uplift') {
          if (variantA.uplift === 'baseline') return direction;
          if (variantB.uplift === 'baseline') return direction * -1;
        }

        return (
          direction *
          (variantA[field] < variantB[field] ? -1 : variantA[field] > variantB[field] ? 1 : 0)
        );
      },

      getVariantsForDefaultConversion() {
        return this.variantList[this.dateKey].variants.map((variant) => {
          return {
            ...variant,
            chanceToWin:
              this.chanceToWin?.[this.activeGoal._id]?.[this.dateKey]?.[this.campaignID]?.allType?.[
                variant._id
              ],
            uplift: this.uplift?.[this.activeGoal._id]?.[this.dateKey]?.[variant._id],
          };
        });
      },
      getVariantsForGoal() {
        return this.variantList[this.dateKey].variants.map((variant) => {
          const goalDataForVariant =
            this.goalConversions?.[this.activeGoal?._id]?.[this.dateKey]?.goal?.variants?.find(
              (v) => {
                return `${v.variantId}` === `${variant._id}`;
              },
            ) || {};

          const { conversions = 0, visitors = 0, conversionRate = null } = goalDataForVariant;

          return {
            ...variant,
            visitors,
            conversions,
            conversionRate,
            chanceToWin:
              this.chanceToWin?.[this.activeGoal._id]?.[this.dateKey]?.[this.campaignID]?.allType?.[
                variant._id
              ],
            uplift: this.uplift?.[this.activeGoal._id]?.[this.dateKey]?.[variant._id],
          };
        });
      },
    },

    apollo: {
      domainCount: {
        query: GET_DOMAINS_COUNT,
      },
    },
  };
</script>
<style lang="sass">
  @import "@/sass/variables/_colors.sass"

  .how-it-works
    display: flex
    align-items: center
    margin-right: auto
  .activate-campaign-popper
    div
      white-space: initial
  .activate-campaign-wrapper
    width: 50%
    position: absolute
    left: 0
    bottom: 0
  .brand-campaigns-ab-testing-not-available
    .vue-js-switch .v-switch-core
      background-color: transparent !important
  .element-hover
    &:hover
      opacity: .7
  .rectangle-custom-class
    border-radius: 7px
    background-color: #FFEFE5
    padding: 8px
    line-height: 1.45
    &-small
      padding: 10px

  .small-rectangle
    display: flex
    justify-content: center
    align-items: center
    margin-right: .5rem
    &.has-schedule
      padding: 0.5625rem
      min-width: 2.5rem
      background: #FFE0CC
      border-radius: 5px

  .variant-table-period-select-box
    .select-content
      width: 20vw
      min-width: 100px
      @media screen and (min-width: 1200px)
        width: 15vw
    &-sm
      .select-content
        min-width: 150px
</style>
<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'

  .subscriber-container
    width: calc(100vw - 10.5rem)

  .activate-campaign-popper
    width: 290px !important
    padding: 1rem !important
  .white-space-nowrap
    white-space: nowrap

  .schedule-infos-top
    position: relative
    z-index: 1
  .schedule-more-btn
    margin-left: auto
    margin-right: 0

  .schedule-info-items,
  .schedule-info-item
    display: flex
    align-items: flex-start
  .schedule-active
    display: flex
    align-items: center
  .schedule-active-inner
    flex-wrap: wrap
    display: flex
    align-items: center
    justify-content: space-between
  .setting-link
    &:hover
      opacity: .7
  .selected-priority
    border: 1px solid #ed5a29
    z-index: 10
    background: rgba(237, 90, 41, 0.1)
    color: #ed5a29
  .brand-settings-summary-local
    padding: 0 16px
    font-size: 0.875rem
    position: relative
  .priority-buttons
    color: darken(#B9BEC6, 40%)
    margin-top: 18px
  .priority-buttons
    .btn
      font-size: 12px
      color: darken(#B9BEC6, 40%)
      &:focus
        outline: none !important
        box-shadow: none
    .border
      &:hover
        z-index: 0
  .priority-popper
    border-color: #505763
    white-space: pre-wrap
    border-radius: 4px
    background-color: #505763
    padding: 8px 12px
    z-index: 30
  .en-button
    width: 76px
  .hu-button
    width: 101px
  .en-button
    .btn
      padding: 3px 4px
  .brand-settings-summary-local
    .col-6
      padding: 0 !important
  @media (max-width: 382px)
    .hu-button
      width: 82px
    .priority-buttons
      .btn
        padding: 3px 2px
  @media (max-width: 325px)
    .hu-button
      width: 77px
    .priority-buttons
      .btn
        padding: 1px 0px
    .brand-settings-summary-local
      padding: 2px 0px
  @media (max-width: 420px)
    .brand-settings-summary-local
      padding: 2px
  @media (min-width: 992px)
    .priority-buttons
      margin-top: 0

  .card
    border: 1px solid $om-gray-300
    padding: 16px
    margin-bottom: 32px

  .card-title
    font-size: 1.5rem
    line-height: 1.33

  .card-body
    padding: 0

  .title
    margin-bottom: 36px

  ::v-deep .brand-campaigns-variants-table
    padding: 0

    div[scope="col"] span
      color: $om-gray-700

    .tbody
      margin-bottom: 12px

      .brand-table-tr
        border: none
        padding: 0

  .coupon-followup-promo
    .card-title
      margin: 0
      display: flex

      .admin-tooltip
        align-self: center
        margin-left: 10px

      svg
        color: $om-gray-700

    .learn-more
      margin-right: 12px

  .alert-block
    &-wrapper
      ul
        li
          font-size: 85%
    &-header
      font-size: 14px
      color: $om-gray-700
      font-weight: 500
    &-title
      color: $om-gray-700
      font-size: 14px
    &-description
      color: $om-gray-600
      font-size: 12px
    &-link
      font-size: 12px
      display: inline-block
      text-decoration: underline
  .campaign-domain-editing
    cursor: text

    &:hover
      background-color: $om-gray-100
</style>

<style lang="sass">
  // Experience product tour design
  @import "@/sass/variables/_colors.sass"

  .variant-emphasized-table .control-variant-delete
    opacity: 0

  .variant-emphasized-table .brand-table-tr:hover .control-variant-delete
    opacity: 1

  div#driver-popover-item[class^="tour-step-experience-"]
    border: 1px solid $om-gray-300
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1)
    width: 42.5rem
    max-width: 42.5rem
    padding: 1rem !important
    .driver-popover-title
      display: none
    .driver-popover-tip.left
      top: 1rem
      &:before
        content: ""
        display: block
        width: 12px
        height: 12px
        left: -7px
        top: -6px
        border: 5px solid $om-gray-300
        border-color: transparent $om-gray-300 transparent transparent
        position: absolute
        z-index: -1
    .tour-experience-illustration
      img
        width: 16.25rem
    .heading
      color: $om-gray-700
      font-weight: 700
      font-size: 1.5rem
      line-height: 2rem
      margin-bottom: .75rem
    .description
      color: $om-gray-700
      font-size: .875rem
      line-height: 1.25rem
    .btn
      font-weight: 500
      text-decoration: none
    .tour-next
      color: $om-orange-500
    .tour-prev
      color: $om-gray-700
</style>
<style lang="sass">
  .goal-select .select-wrapper
    min-width: 20rem
</style>
